<template>
    <div>
        <vs-row>
            <!-- navbar -->
            <vs-col class="sm:px-2 px-0 py-2" vs-type="flex" vs-justify="space-between" vs-align="center">
                <div class="flex">
                    <div class="mr-4 flex text-primary cursor-pointer hover:font-semibold" @click="$router.go(-1)">
                        <feather-icon class="mr-1" icon="ChevronLeftIcon" svgClasses="h-4 w-5"></feather-icon>
                        <span>{{ $t('apptHistory.prev') }}</span>
                    </div>
                    <h3>{{ pageTitle }}</h3>
                </div>
                <div class="flex items-center text-primary cursor-pointer hover:font-semibold" @click="download">
                    <feather-icon svgClasses="w-4 h-4" icon="DownloadIcon"></feather-icon>
                    <span v-if="windowWidth > 600">{{ $t('apptHistory.downloadReport') }}</span>
                </div>
                <div v-if="windowWidth <= 600">
                    <vs-button class="cd-button-2w-icon" type="flat" icon-pack="feather" icon="icon-filter" @click="switchFilter">{{ $t('apptHistory.filter') }}</vs-button>
                </div>
            </vs-col>
            <!-- filter -->
            <vs-col vs-w="3" vs-xs="12">
                <vs-sidebar class="cd-emr-sidebar" :style="windowWidth <= 600 ? 'height: 74vh;' : 'height: 100%;'" :static-position="menuActive" :hidden-background="true" v-model="menuActive">
                    <VuePerfectScrollbar :style="windowWidth <= 600 ? 'height: 62vh;' : ''" :settings="settings">
                        <!-- 醫事機構 -->
                        <div class="mt-4 cd-appthistory-filter-group">
                            <span>{{ $t('apptHistory.medicalInstitution') }}</span>
                            <el-select class="w-full" popper-class="cd-appt-popper" v-model="selectedPartnerId" @change="searchQueueLog">
                                <el-option v-for="item in partnerlist" :key="item.id" :label="item.company" :value="item.id"> </el-option>
                            </el-select>
                        </div>
                        <!-- qid＆姓名＆電話＆身份證 -->
                        <div class="cd-appthistory-filter-group">
                            <span>{{ $t('apptHistory.searchPatient') }}</span>
                            <vs-input class="w-full" v-model="query" @change="searchQueueLog"></vs-input>
                        </div>
                        <!-- 日期 -->
                        <div class="cd-appthistory-filter-group">
                            <!-- 日期篩選捷徑 -->
                            <div class="flex cd-form-group items-center whitespace-no-wrap">
                                <feather-icon class="mr-1 text-success" icon="CalendarIcon" svgClasses="h-4 w-5"></feather-icon>
                                <span class="mr-1 text-success cd-p-pointer" @click="enterDateInput('none')">{{ $t('apptHistory.all') }}</span>
                                <span class="mr-1 text-success cd-p-pointer" @click="enterDateInput('week')">{{ $t('apptHistory.week') }}</span>
                                <span class="mr-1 text-success cd-p-pointer" @click="enterDateInput('month')">{{ $t('apptHistory.month') }}</span>
                                <span class="mr-1 text-success cd-p-pointer" @click="enterDateInput(7)">{{ $t('apptHistory.sevenDays') }}</span>
                                <span class="mr-1 text-success cd-p-pointer" @click="enterDateInput(30)">{{ $t('apptHistory.thirtyDays') }}</span>
                            </div>
                            <div class="flex cd-form-group items-center">
                                <span class="mr-1 whitespace-no-wrap">{{ $t('apptHistory.startDate') }}</span>
                                <el-date-picker class="w-full" popper-class="cd-appt-popper" v-model="startDate" type="date" :placeholder="$t('apptHistory.startDate')" value-format="yyyy-MM-dd" clearable @change="searchQueueLog"> </el-date-picker>
                            </div>
                            <div class="flex items-center">
                                <span class="mr-1 whitespace-no-wrap">{{ $t('apptHistory.endDate') }}</span>
                                <el-date-picker class="w-full" popper-class="cd-appt-popper" v-model="endDate" type="date" :placeholder="$t('apptHistory.endDate')" value-format="yyyy-MM-dd" clearable @change="searchQueueLog"> </el-date-picker>
                            </div>
                        </div>
                        <!-- 視訊＆到院 -->
                        <div class="cd-appthistory-filter-group">
                            <vs-radio v-model="site" vs-name="site1" vs-value="0" @change="searchQueueLog">{{ $t('apptHistory.site') }}</vs-radio
                            ><br />
                            <vs-radio class="ml-6" v-model="site" vs-name="site2" vs-value="2" @change="searchQueueLog">{{ $t('apptHistory.videoChat') }}</vs-radio
                            ><br />
                            <vs-radio class="ml-6" v-model="site" vs-name="site3" vs-value="1" @change="searchQueueLog">{{ $t('apptHistory.onsiteVisit') }}</vs-radio>
                        </div>
                        <!-- 掛號狀態 -->
                        <div class="cd-appthistory-filter-group">
                            <vs-radio v-model="status" vs-name="status1" vs-value="0" @change="searchQueueLog">{{ $t('apptHistory.status') }}</vs-radio
                            ><br />
                            <vs-radio class="ml-6" v-model="status" vs-name="status2" vs-value="1" @change="searchQueueLog">{{ $t('apptHistory.validQueue') }}</vs-radio
                            ><br />
                            <vs-radio class="ml-12" v-model="status" vs-name="status2" vs-value="2" @change="searchQueueLog">{{ $t('apptHistory.unfinish') }}</vs-radio
                            ><br />
                            <vs-radio class="ml-12" v-model="status" vs-name="status3" vs-value="3" @change="searchQueueLog">{{ $t('apptHistory.finish') }}</vs-radio
                            ><br />
                            <vs-radio class="ml-6" v-model="status" vs-name="status4" vs-value="4" @change="searchQueueLog">{{ $t('apptHistory.cancel') }}</vs-radio
                            ><br />
                            <vs-radio class="ml-12" v-model="status" vs-name="status5" vs-value="5" @change="searchQueueLog">{{ $t('apptHistory.patientCancel') }}</vs-radio
                            ><br />
                            <vs-radio class="ml-12" v-model="status" vs-name="status6" vs-value="6" @change="searchQueueLog">{{ $t('apptHistory.systemCancel') }}</vs-radio>
                        </div>
                        <!-- 掛號類型 -->
                        <div class="cd-appthistory-filter-group">
                            <vs-radio v-model="type" vs-name="type1" vs-value="0" @change="searchQueueLog">{{ $t('apptHistory.type') }}</vs-radio
                            ><br />
                            <vs-radio class="ml-6" v-model="type" vs-name="type2" vs-value="1" @change="searchQueueLog">{{ $t('apptHistory.generalAppt') }}</vs-radio
                            ><br />
                            <vs-radio class="ml-6" v-model="type" vs-name="type3" vs-value="2" @change="searchQueueLog">{{ $t('apptHistory.emergencyAppt') }}</vs-radio
                            ><br />
                            <vs-radio class="ml-6" v-model="type" vs-name="type4" vs-value="3" @change="searchQueueLog">{{ $t('apptHistory.specAppt') }}</vs-radio>
                        </div>
                        <!-- 掛號者 -->
                        <div class="cd-appthistory-filter-group">
                            <vs-radio v-model="proxy" vs-name="proxy1" vs-value="0" @change="searchQueueLog">{{ $t('apptHistory.proxy') }}</vs-radio
                            ><br />
                            <vs-radio class="ml-6" v-model="proxy" vs-name="proxy2" vs-value="1" @change="searchQueueLog">{{ $t('apptHistory.patientProxy') }}</vs-radio
                            ><br />
                            <vs-radio class="ml-6" v-model="proxy" vs-name="proxy3" vs-value="2" @change="searchQueueLog">{{ $t('apptHistory.doctorProxy') }}</vs-radio>
                        </div>
                        <!-- 顯示有錄影檔的掛號 -->
                        <!-- <div class="cd-appthistory-filter-group">
                            <vs-checkbox v-model="showVideo" @change="searchQueueLog">{{ $t('apptHistory.showVideo') }}</vs-checkbox>
                        </div> -->
                        <!-- 每頁顯示筆數 -->
                        <div class="cd-appthistory-filter-group">
                            <span>{{ $t('apptHistory.limitPerPage') }}</span>
                            <div>
                                <vs-radio class="mx-1" v-model="limit" vs-value="10" @change="searchQueueLog">{{ $t('apptHistory.limit', { num: 10 }) }}</vs-radio>
                                <vs-radio class="mx-1" v-model="limit" vs-value="20" @change="searchQueueLog">{{ $t('apptHistory.limit', { num: 20 }) }}</vs-radio>
                                <vs-radio class="mx-1" v-model="limit" vs-value="30" @change="searchQueueLog">{{ $t('apptHistory.limit', { num: 30 }) }}</vs-radio>
                                <vs-radio class="mx-1" v-model="limit" vs-value="40" @change="searchQueueLog">{{ $t('apptHistory.limit', { num: 40 }) }}</vs-radio>
                            </div>
                        </div>
                    </VuePerfectScrollbar>
                    <vs-divider v-if="windowWidth <= 600" />
                    <!-- 篩選按鈕 -->
                    <div class="cd-appthistory-filter-group flex justify-around" v-if="windowWidth <= 600">
                        <vs-button type="border" @click="reset">{{ $t('popup.reset') }}</vs-button>
                        <vs-button class="cd-button-filled" @click="switchFilter">{{ $t('popup.confirm') }}</vs-button>
                    </div>
                </vs-sidebar>
            </vs-col>
            <!-- list -->
            <vs-col class="cd-appthistory-list" vs-w="9" vs-xs="12" v-if="!menu">
                <div class="sm:px-6 px-0 mb-base" v-for="(item, index) in rsList" :key="index">
                    <div class="title">
                        <span class="text-primary cursor-pointer hover:underline" @click="goToEmr(item.pid, item.queue_id)">
                            {{ item.name }}&nbsp;&nbsp; {{ getSex(item.sex) }}&nbsp;&nbsp; {{ getAge(item.birthday) }}&nbsp;&nbsp;
                            {{ item.account }}
                        </span>
                        <vs-dropdown class="cursor-pointer" vs-custom-content vs-trigger-click>
                            <vs-button class="" type="flat" icon-pack="feather" icon="icon-more-vertical"></vs-button>
                            <vs-dropdown-menu>
                                <ul style="min-width: 7rem">
                                    <li class="flex p-2 cursor-pointer hover:bg-primary hover:text-white" @click="goToEmr(item.pid, item.queue_id)">
                                        <span>{{ $t('apptHistory.mr') }}</span>
                                    </li>
                                    <li class="flex p-2 cursor-pointer hover:bg-primary hover:text-white" @click="showChatPopup(item.mid, item.name)">
                                        <span>{{ $t('membersMgmt.sendMsg') }}</span>
                                    </li>
                                    <li class="flex p-2 cursor-pointer hover:bg-primary hover:text-white" @click="showApptPopup(0, item)">
                                        <span>{{ $t('apptHistory.generalAppt') }}</span>
                                    </li>
                                    <li class="flex p-2 cursor-pointer hover:bg-primary hover:text-white" @click="showApptPopup(1, item)">
                                        <span>{{ $t('apptHistory.specAppt') }}</span>
                                    </li>
                                    <li class="flex p-2 cursor-pointer hover:bg-primary hover:text-white" @click="openRxPopup(item.mr_id)" v-if="apptConfig.exportRX.show">
                                        <span>{{ $t('emr.appt.checkPrescription') }}</span>
                                    </li>
                                </ul>
                            </vs-dropdown-menu>
                        </vs-dropdown>
                    </div>
                    <div class="content">
                        <span class="mr-4 whitespace-no-wrap">#{{ item.queue_id }}</span>
                        <span class="mr-4 whitespace-no-wrap">{{ item.date }}</span>
                        <span class="mr-4 whitespace-no-wrap">{{ getInterval(item.interval) | getReservationTime(item.reservation_time) }}</span>
                        <span class="mr-4 whitespace-no-wrap">{{ getSite(item.site) }}</span>
                        <span class="mr-4 whitespace-no-wrap">{{ getType(item.spec_note, item.interval) }}</span>
                        <span class="cd-appthistory-text-small whitespace-no-wrap" v-if="item.proxyName">{{
                            $t('apptHistory.referAppt', {
                                name: item.proxyName,
                            })
                        }}</span>
                    </div>
                    <div class="content">
                        <span class="px-1 cd-appthistory-text-small border border-solid d-theme-border">{{ getStatus(item.finish, item.closed, item.system_closed, item.cancel_time) }}</span>
                        <span class="mx-2" v-if="item.p_memo && (item.finish == 1 || item.closed == 0)">｜</span>
                        <span v-if="item.p_memo && (item.finish == 1 || item.closed == 0)">{{ $t('apptHistory.service') }}{{ item.p_memo }}</span>
                    </div>
                    <div class="content" v-if="item.finish == 1 || item.closed == 0">
                        <span v-if="item.json_a">ICD10:&nbsp;</span>
                        <span v-for="(data, index) in item.json_a" :key="index" v-show="item.json_a">{{ data.disease_code | getLength(item.json_a) }}</span>
                        <span class="mx-2" v-if="item.memo_p">｜</span>
                        <span v-if="item.memo_p">{{ $t('apptHistory.suggestion') }}{{ item.memo_p }}</span>
                    </div>
                    <div class="content" v-if="item.video && item.video.length > 0 && (item.video[0].video_file != null || item.video[0].is_p2p == 1)">
                        <vs-dropdown class="flex items-center text-primary cursor-pointer hover:font-semibold" vs-custom-content vs-trigger-click>
                            <feather-icon class="mr-2" icon="VideoIcon" svgClasses="h-5 w-5" />
                            <span class="mr-1">{{ item.video[0].record_start_time }}</span>
                            <span v-if="item.video.length > 1">({{ item.video.length }})</span>
                            <vs-dropdown-menu>
                                <div class="p-2 flex hover:text-white hover:bg-primary cursor-pointer" v-for="(data, index) in item.video" :key="index" @click.stop="viewVideo(data)">
                                    <feather-icon class="mr-2" icon="VideoIcon" svgClasses="h-5 w-5" />
                                    <span>{{ data.record_start_time }}</span>
                                </div>
                            </vs-dropdown-menu>
                        </vs-dropdown>
                    </div>
                </div>
                <!-- 查無資料 -->
                <div class="text-center" v-if="total == 0">
                    {{ $t('apptHistory.noData') }}
                </div>
                <vs-pagination :max="windowWidth <= 600 ? 7 : 9" :total="total" v-model="page" @change="searchQueueLog(false)" v-if="total != 0"></vs-pagination>
            </vs-col>
        </vs-row>
        <!-- 錄影檔案 -->
        <vs-popup @close="videopath = ''" :title="$t('apptHistory.recordFile')" :active.sync="videoPopupActive">
            <div v-if="s_video_info">
                <iframe :src="videopath" width="400vh" :height="windowHeight - 200" alt="" v-if="s_video_info.is_p2p == 1" />
                <video width="100%" height="400vh" controls="controls" autoplay="autoplay" v-else>
                    <source :src="videopath" />
                    Your browser does not support the video tag.
                </video>
            </div>
        </vs-popup>
        <!-- 掛號視窗 -->
        <appt-btn :activePopup="openApptPopup" :mode="apptMode" :patientData="patient" @closeApptPopup="closeApptPopup"></appt-btn>
        <!-- 顯示處方籤 -->
        <rx-popup :mr_id="mr_id" :RXPopupActive="RXPopup" @closePopup="closeRxPopup"></rx-popup>
        <!-- 訊息視窗 -->
        <send-chat :chatPopup="openChatPopup" :smid="parseInt(midChat)" :sname="nameChat" @closeChat="closeChatPopup"></send-chat>
    </div>
</template>
<script>
import { searchQueueLog, getVideoList } from '@/api/user'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import FeatherIcon from '../components/FeatherIcon.vue'
import ApptBtn from '../components/apptBtn.vue'
import rxPopup from '@/components/rxPopup'
import sendChat from '@/components/sendChat'
export default {
    components: {
        VuePerfectScrollbar,
        FeatherIcon,
        ApptBtn,
        rxPopup,
        sendChat,
    },
    created() {
        this.selectedPartnerId = this.partnerid
        this.searchQueueLog()
    },
    computed: {
        // 主選單開關
        menuActive: {
            get() {
                if (this.windowWidth > 600) {
                    return true
                } else {
                    return this.menu
                }
            },
            set(val) {},
        },
        partnerid() {
            return this.$store.getters.partner
        },
        partnerlist() {
            return JSON.parse(localStorage.getItem('partnerlist'))
        },
        windowWidth() {
            return this.$store.state.windowWidth
        },
        windowHeight() {
            return window.innerHeight
        },
        did() {
            return this.$store.getters.did
        },
        dmid() {
            return this.$store.state.mid
        },
        lang() {
            return this.$i18n.locale
        },
        apptConfig() {
            return JSON.parse(localStorage.getItem('his_config')).appt
        },
        pageTitle() {
            var data = JSON.parse(localStorage.getItem('his_config')).menu
            data = _.filter(data, { name: 'appthistory' })
            if (this.lang == 'tw') {
                return data[0].i18n.tw
            } else if (this.lang == 'cn') {
                return data[0].i18n.cn
            } else {
                return data[0].i18n.en
            }
        },
    },
    data() {
        return {
            menu: false,
            rsList: [],
            query: null,
            selectedPartnerId: null,
            site: 0,
            startDate: null,
            endDate: null,
            status: 1,
            type: 0,
            showVideo: false,
            limit: 20,
            page: 1,
            total: 0,
            proxy: 0,
            videopath: '',
            videoPopupActive: false,
            settings: {
                maxScrollbarLength: 60,
                wheelSpeed: 0.3,
            },
            openApptPopup: false,
            apptMode: 0,
            patient: {
                pid: null,
                name: null,
            },
            RXPopup: false,
            mr_id: null,
            openChatPopup: false,
            midChat: 0,
            nameChat: '',
            s_video_info: null,
        }
    },
    filters: {
        getReservationTime(val, time) {
            return time ? time : val
        },
        getLength(val, arr) {
            return arr[arr.length - 1].disease_code == val ? val : val + '、'
        },
    },
    methods: {
        // 搜尋掛號紀錄
        searchQueueLog(resetPage = true) {
            var _self = this
            let payload = {}
            this.page = resetPage ? 1 : this.page
            if (this.query) payload['query'] = this.query
            if (this.startDate) payload['start_date'] = this.startDate
            if (this.endDate) payload['end_date'] = this.endDate
            if (this.selectedPartnerId) payload['partner_id'] = this.selectedPartnerId
            if (this.site && this.site != 0) payload['site'] = this.site
            if (this.status && this.status != 0) payload['status'] = this.status
            if (this.type && this.type != 0) payload['type'] = this.type
            if (this.proxy && this.proxy != 0) payload['proxy'] = this.proxy
            if (this.showVideo) payload['video'] = this.showVideo
            if (this.limit) payload['limit'] = this.limit
            if (this.page) payload['page'] = this.page
            searchQueueLog(payload).then((res) => {
                if (res.data.status == 'OK') {
                    this.rsList = _.cloneDeep(res.data.data)
                    _.forEach(this.rsList, function (v, i) {
                        _self.getVideoList(v.video, i)
                    })
                    this.total = Math.ceil(res.data.total / this.limit)
                }
            })
        },
        // 掛號紀錄查詢 填入日期
        enterDateInput(index) {
            const today = new Date()
            if (index == 'none') {
                this.endDate = this.startDate = null
            } else if (index == 'week') {
                this.endDate = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate()
                today.setDate(today.getDate() - today.getDay() + 1)
                this.startDate = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate()
            } else if (index == 'month') {
                this.startDate = today.getFullYear() + '-' + (today.getMonth() + 1) + '-01'
                this.endDate = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate()
            } else {
                this.endDate = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate()
                today.setDate(today.getDate() - index + 1)
                this.startDate = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate()
            }
            this.searchQueueLog()
        },
        // 開啟 錄影檔案 視窗
        viewVideo(data) {
            if (data.is_p2p == 0) {
                var caseday = this.$moment(data.record_start_time).format('YYYY-MM-DD')
                var today = this.$moment().format('YYYY-MM-DD')
                var placeOld = false
                if (caseday != today) {
                    placeOld = true
                }
                if (placeOld) {
                    this.videopath = 'https://storage.googleapis.com/curdoctor_recording_backup/' + data.video_file
                } else {
                    this.videopath = process.env.VUE_APP_MCU + '/recording/' + data.video_file
                }
            } else {
                this.videopath = process.env.VUE_APP_URL + '/play.html?roomID=' + data.room_id + '&userID=curdr_' + this.partnerid + '_' + this.dmid + '_1' + '&groupID=' + data.groupID
                // this.videopath = 'http://localhost:8081' + '/play.html?roomID=' + data.room_id
            }
            this.s_video_info = data
            this.videoPopupActive = true
        },
        // 關閉 錄影檔案 視窗
        close() {
            this.videopath = ''
            this.s_video_info = null
            this.videoPopupActive = false
        },
        // 導向掛號主頁
        goToEmr(pid, qid) {
            this.$router.push('/emr2/' + pid + '/' + qid)
        },
        // 開關篩選sidebar (手機版)
        switchFilter() {
            this.menu = !this.menu
        },
        // 篩選回到預設
        reset() {
            this.query = null
            this.selectedPartnerId = null
            this.site = 0
            this.startDate = null
            this.endDate = null
            this.status = 1
            this.type = 0
            this.showVideo = false
        },
        // 開啟掛號視窗
        showApptPopup(index, item) {
            this.apptMode = index
            this.patient.pid = parseInt(item.pid)
            this.patient.name = item.name
            this.openApptPopup = true
        },
        // 關閉掛號視窗
        closeApptPopup(v) {
            this.openApptPopup = v
        },
        // 取性別
        getSex(val) {
            if (val == 1) {
                return this.$tc('apptHistory.male')
            } else if (val == 2) {
                return this.$tc('apptHistory.female')
            } else {
                return '-'
            }
        },
        // 取年齡
        getAge(val) {
            if (val === '0000-00-00' || !val) {
                return '--'
            } else {
                const ageMS = Date.parse(Date()) - Date.parse(val)
                const age = new Date()
                age.setTime(ageMS)
                const ageYear = age.getFullYear() - 1970
                return ageYear
            }
        },
        // 取時段
        getInterval(interval) {
            if (interval == 1) {
                return this.$t('apptHistory.am')
            } else if (interval == 2) {
                return this.$t('apptHistory.pm')
            } else if (interval == 3) {
                return this.$t('apptHistory.night')
            } else if (interval != 0) {
                return null
            }
        },
        // 取看診方式
        getSite(val) {
            return val == 1 ? this.$t('apptHistory.onsiteVisit') : this.$t('apptHistory.videoChat')
        },
        // 取掛號類型
        getType(spec_note, interval) {
            if (spec_note == 1) {
                return this.$t('apptHistory.specAppt')
            } else if (interval == 0) {
                return this.$t('apptHistory.emergencyAppt')
            } else {
                return this.$t('apptHistory.generalAppt')
            }
        },
        // 取掛號狀態
        getStatus(finish, closed, system_closed, cancel_time) {
            if (finish == 1) {
                return this.$t('apptHistory.finish')
            } else if (closed == 1 && system_closed == 0) {
                return this.$t('apptHistory.patientCancel') + ' ' + cancel_time
            } else if (closed == 1 && system_closed == 1) {
                return this.$t('apptHistory.systemCancel') + ' ' + cancel_time
            } else {
                return this.$t('apptHistory.unfinish')
            }
        },
        // 下載成excel檔
        download() {
            let payload = { token: localStorage.getItem('jwt') }
            if (this.did) payload['sdid'] = this.did
            if (this.query) payload['query'] = this.query
            if (this.startDate) payload['start_date'] = this.startDate
            if (this.endDate) payload['end_date'] = this.endDate
            if (this.selectedPartnerId) payload['partner_id'] = this.selectedPartnerId
            if (this.site && this.site != 0) payload['site'] = this.site
            if (this.status && this.status != 0) payload['status'] = this.status
            if (this.type && this.type != 0) payload['type'] = this.type
            if (this.proxy && this.proxy != 0) payload['proxy'] = this.proxy
            if (this.showVideo) payload['video'] = this.showVideo
            var url = process.env.VUE_APP_API_URL_HIS
            url += '/api/downloadApptHistoryExcel/' + this.parseParams(payload)
            window.open(url)
        },
        parseParams(data) {
            try {
                var tempArr = []
                for (var i in data) {
                    var key = encodeURIComponent(i)
                    var value = encodeURIComponent(data[i])
                    tempArr.push(key + '=' + value)
                }
                var urlParamsStr = tempArr.join('&')
                return urlParamsStr
            } catch (err) {
                return ''
            }
        },
        // 開啟處方簽
        openRxPopup(mr_id) {
            this.mr_id = mr_id
            this.RXPopup = true
            document.body.style.overflow = 'hidden'
        },
        // 關閉處方簽
        closeRxPopup() {
            this.RXPopup = false
            document.body.style.overflow = 'visible'
        },
        // 開啟訊息視窗
        showChatPopup(mid, name) {
            document.body.style.overflow = 'hidden'
            this.midChat = mid
            this.nameChat = name
            this.openChatPopup = true
        },
        // 關閉訊息視窗
        closeChatPopup(v) {
            document.body.style.overflow = 'visible'
            this.midChat = 0
            this.openChatPopup = v
        },
        // 取p2p影片清單
        getVideoList(data, index) {
            var _self = this
            _.forEach(data, function (v) {
                if (v.is_p2p != 1) return
                getVideoList(v.room_id).then((res) => {
                    const rs = _.cloneDeep(res.data)
                    _self.rsList[index].video = rs.map((item) => ({
                        ...data[0], // 複製原始陣列的第一個元素
                        groupID: item.groupID,
                        record_start_time: item.start, // 更新 record_start_time 為 start
                    }))
                })
            })
        },
    },
}
</script>
